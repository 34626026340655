import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Helmet from 'react-helmet';
import Favicon from "../images/favicon.ico";

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>{post.frontmatter.title} - Kevin Blank - kevinblank.com</title>
        <link rel="icon" type="image/x-icon" href={Favicon}></link>
      </Helmet>
      <Container>
          <Row>
            <Col style={bigSpacing} xs={12} lg={5}>
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h1 itemProp="headline">{post.frontmatter.title}</h1>
                {/*<p>{post.frontmatter.date}</p>*/}
              </header>
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
              <hr />
            </article>
            </Col>
            <Col style={bigSpacing} xs={12} lg={6}>
            {pageContext.postImages.map(image => 
              <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="something" key={image.relativePath}/>
            )}
            </Col>
          </Row>
        </Container>
      
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

// styles
const bigSpacing = {
  margin: "80px 10px",
}